<template>
  <div v-if="limits.length" class="wallet-limit">
    <div class="wallet-limit__top">
      <div class="wallet-limit__title">
        <span>{{ getContent(limitsContent, defaultLimitsContent, 'deposit.wallet.label') }}</span>
        <button @click="goToLimits" type="button">
          {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.checkDetails') }}
        </button>
      </div>
      <div class="wallet-limit__list">
        <div
          v-for="limit in limits"
          :key="limit.id"
          :class="['wallet-limit__item', { 'wallet-limit__item_warning': limit.showWarning }]"
        >
          <div class="wallet-limit__details">
            <div class="wallet-limit__name">
              {{
                getContent(
                  globalComponentsContent,
                  defaultLocaleGlobalComponentsContent,
                  `constants.limitPeriods.${limit.period}`
                )
              }}
              <div class="wallet-limit__tooltip">
                <button class="wallet-limit__tooltip-btn" type="button">?</button>
                <div class="wallet-limit__tooltip-wrapper">
                  <div class="wallet-limit__tooltip-inner">
                    {{ formatStatus(limit.expiredAt) }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="164" height="12" viewBox="0 0 164 12">
                      <path
                        d="M20 -0.5L19.1295 -0.5L19.5681 0.251936L23.9768 7.80964C25.3273 10.1249 28.6727 10.1249 30.0232 7.80965L34.4319 0.251937L34.8705 -0.499999L34 -0.499999L20 -0.5Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <span>{{ limit.currentLimitValue }} / {{ limit.limitValueText }}</span>
          </div>
          <div
            class="wallet-limit__bar"
            :style="`--bar-try-width: ${limit.tryProgress}%;
            --bar-current-limit-width: ${limit.currentProgress}%;
            --bar-current-limit-opacity: ${limit.currentProgress <= 0 || limit.showWarning ? 0 : 1}`"
          />
        </div>
      </div>
    </div>
    <div v-if="showInfoBlock" class="wallet-limit__info">
      <div class="wallet-limit__info-title">
        {{ getContent(limitsContent, defaultLimitsContent, 'deposit.wallet.title') }}
      </div>
      <div class="wallet-limit__desc">
        {{ getContent(limitsContent, defaultLimitsContent, 'deposit.wallet.description') }}
      </div>
      <button-base class="wallet-limit__btn" type="primary" @click="goToLimits">
        {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.openSettings') }}
      </button-base>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ModalName } from '@skeleton/consts/modals';

  const emits = defineEmits(['update:limitError', 'update:depositError']);

  const props = defineProps<{
    selectedCurrency?: string;
    limitError?: boolean;
    depositError?: boolean;
    depositBalance?: string | number;
    showAllLimits?: boolean;
  }>();

  const dayjs = useDayjs();
  const { closeModalSync, closeModal } = useModalStore();
  const { getContent, formatBalance, localizePath } = useProjectMethods();

  const { layoutData, defaultLocaleLayoutData, globalComponentsContent, defaultLocaleGlobalComponentsContent } =
    useGlobalStore();

  const { activeLimits, limitsContent, defaultLimitsContent } = storeToRefs(useLimitsStore());

  const limitValueText = (limitAmount: number, limitCurrency?: string) => {
    const { amount, currency } = formatBalance(limitCurrency, limitAmount);
    return `${amount} ${currency}`;
  };

  const limits = computed(() => {
    return activeLimits.value
      .filter(item => {
        const isActiveDepositLimit = item.definition === 3 && item.status === 1;

        return props.showAllLimits
          ? isActiveDepositLimit
          : item.currency === props.selectedCurrency && isActiveDepositLimit;
      })
      .map(item => {
        const currentAmount = item.currentAmount ?? 0;
        const deposit = Number(props.depositBalance ?? 0) + currentAmount;
        const showWarning = deposit > (item.amount ?? 0) || currentAmount >= (item.amount ?? 0);
        const depositError = currentAmount >= (item.amount ?? 0);

        return {
          ...item,
          tryProgress: !item.amount || !(currentAmount + deposit) ? 0 : Math.min((deposit * 100) / item.amount, 100),
          currentProgress: !item.amount || !currentAmount ? 0 : Math.min((currentAmount * 100) / item.amount, 100),
          showWarning,
          limitValueText: limitValueText(item.amount ?? 0, item.currency ?? undefined),
          currentLimitValue: depositError ? currentAmount : currentAmount + Number(props.depositBalance ?? 0),
          depositError,
        };
      });
  });

  const showInfoBlock = computed(() => {
    return !!limits.value.find(item => item.depositError);
  });

  const activeStatusContent = computed(() =>
    getContent(limitsContent.value, defaultLimitsContent.value, 'activeStatusLabel')
  );

  const formatStatus = (expiredAt: string | null) => {
    if (!expiredAt) return '';
    const dateValue = dayjs(expiredAt).format('DD.MM.YYYY HH:mm');
    return activeStatusContent.value ? activeStatusContent.value.replace('{date}', dateValue) : '';
  };

  watch(
    limits,
    () => {
      emits('update:limitError', !!limits.value.find(item => item.showWarning));
    },
    { immediate: true }
  );

  watch(
    showInfoBlock,
    val => {
      emits('update:depositError', val);
    },
    { immediate: true }
  );

  const goToLimits = (): void => {
    const router = useRouter();
    closeModalSync(ModalName.WALLET);
    closeModalSync(ModalName.WALLET_TELEGRAM_APP);
    router.push(localizePath('/profile/limits'));
  };
</script>

<style src="~/assets/styles/components/wallet/limit.scss" lang="scss" />
