import { storeToRefs } from 'pinia';
import * as Sentry from '@sentry/nuxt';

import { useApiAuthInstance } from '@skeleton/core/assets/apiAuthInstance';
import type { ISimpleListItem } from '~/types';

export const useTransactionOperations = (props: { method: string}) => {
  const { formatBalance } = useProjectMethods();
  const { iconUrl } = useDynamicIcon('currency');
  const { accounts } = storeToRefs(useWalletStore());
  const { getDepositMethods } = useCoreWalletApi();

  const initialPayDefinition = props.method.includes('.deposit') ? 'deposit' : 'withdrawal';

  const currentCurrencyId = ref('');
  const allSupportedCurrencies = ref<string[]>([]);
  const isLoadingSupportedCurrencies = ref(false);
  const isLoadingDepositMethodsForCurrentCurrency = ref(false);

  const selectedCurrency = computed(() => {
    return (
      supportedTransactionAccounts.value?.find(
        (account) => account.id === currentCurrencyId.value
      )?.currency ?? ''
    );
  });

  const fetchSupportedCurrencies = async () => {
    if (!initialPayDefinition || !props.method) return;
    try {
      isLoadingSupportedCurrencies.value = true;
      const { data } = await useApiAuthInstance(`/api/payment/currencies/${initialPayDefinition}/${props.method}`);
      allSupportedCurrencies.value = data;
    } catch (e) {
      Sentry.captureException(e);
      allSupportedCurrencies.value = [];
    } finally {
      isLoadingSupportedCurrencies.value = false;
    }
  };

  const supportedTransactionAccounts = computed(() => {
    return accounts.value.filter((account) =>
      allSupportedCurrencies.value.some((currency) => currency === account.currency)
    );
  });

  const accountSelectOptions = computed(() => {
    const accountsOptions = supportedTransactionAccounts.value.map((account) => {
      const { currency, amount } = formatBalance(account.currency, account.balance);
      return {
        mask: `${iconUrl.value}/${account.currency}.svg`,
        value: `${amount} ${currency}`,
        code: account.currency,
      };
    });

    return [...accountsOptions];
  });

  const currencyList = computed((): ISimpleListItem[] => {
    return supportedTransactionAccounts.value.map((account) => ({
      id: account.id,
      name: account.currency,
      icon: `${iconUrl.value}/${account.currency}.svg`,
      amount: {
        currenValue: `${account.balance}`,
        baseValue: account.baseCurrencyBalance,
        baseCurrencySymbol: account.baseCurrencySymbol
      },
    }));
  });

  const balanceObject = computed(() => {
    const account = supportedTransactionAccounts.value.find((item) => item.id === currentCurrencyId.value);

    return accountSelectOptions.value.find((item) => {
      return item.code === account?.currency;
    });
  });

  const getDepositMethodsForCurrentCurrency = async () => {
    try {
      isLoadingDepositMethodsForCurrentCurrency.value = true;
      return await getDepositMethods(selectedCurrency.value);
    } catch (e) {
      console.log('getDepositMethod', e);
      Sentry.captureException(e);
      return [];
    } finally {
      isLoadingDepositMethodsForCurrentCurrency.value = false;
    }
  };

  watch(() => props.method, async () => {
    await fetchSupportedCurrencies();
  }, { immediate: true });

  watch(supportedTransactionAccounts, () => {
    const [account] = supportedTransactionAccounts.value;
    currentCurrencyId.value = account?.id ?? '';
  });

  return {
    accountSelectOptions,
    currencyList,
    supportedTransactionAccounts,
    balanceObject,
    currentCurrencyId,
    selectedCurrency,
    getDepositMethodsForCurrentCurrency,
    isLoadingSupportedCurrencies,
    isLoadingDepositMethodsForCurrentCurrency
  };
};