interface HasId {
  id: string;
}

export const sortNetworkList = <T extends HasId>(networkItems: T[]): T[] => {
  const idList = ['TRC20', 'BEP20', 'ERC20'];

  return networkItems.sort((a, b) => {
    const indexA = idList.indexOf(a.id);
    const indexB = idList.indexOf(b.id);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    return 0;
  });
};
