<template>
  <div ref="root" class="deposit-bonus-code">
    <div class="deposit-bonus-code__label">
      {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.bonusCode.label') || '' }}
    </div>

    <div class="deposit-bonus-code__code">
      <form-input-text
        ref="bonusField"
        v-model:value="bonusValue"
        :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.bonusCode.placeholder') || ''"
        name="bonus-code"
        autocomplete="off"
        :isDisabled="!!depositBonusCode"
        @touchend="focusInput"
        @touchmove="touchMove = true"
      />

      <button-base
        type="primary"
        size="md"
        :isDisabled="bonusChecking"
        @click="toggleBonusCode"
      >
        <atomic-spinner :is-shown="bonusChecking"/>
        {{ depositBonusCode ? getContent(popupsData, defaultLocalePopupsData, 'wallet.deposit.cancelBonusCode')
          : getContent(popupsData, defaultLocalePopupsData, 'wallet.deposit.addBonusCode') }}
      </button-base>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { BONUS_CONSTANTS } from '@skeleton/consts/bonusParams';

  const globalStore = useGlobalStore();

  const {
    popupsData,
    defaultLocalePopupsData,
    fieldsSettings,
    defaultLocaleFieldsSettings,
    alertsData,
    defaultLocaleAlertsData
  } = storeToRefs(globalStore);

  const { getContent } = useProjectMethods();

  const bonusValue = ref('');
  const bonusChecking = ref(false);

  const { addBonusCode, deleteBonusCode } = useCoreBonusApi();
  const bonusStore = useBonusStore();
  const { depositBonusCode } = storeToRefs(bonusStore);

  const sendManualBonus = async (): Promise<boolean> => {
    try {
      const response = await addBonusCode(bonusValue.value, BONUS_CONSTANTS.DEFAULT_ATTEMPT);
      return response.status === BONUS_CONSTANTS.SUCCESS_STATUS;
    } catch (err) {
      return false;
    }
  };

  const toggleBonusCode = async (): Promise<void> => {
    if (bonusChecking.value) return;
    bonusChecking.value = true;

    if (depositBonusCode.value) {
      await deleteBonusCode(depositBonusCode.value?.id as string);
      bonusValue.value = '';
      depositBonusCode.value = undefined;
    } else if (bonusValue.value) {
      useEvent('analyticsEvent', { event: 'walletPromoSubmit' });

      const bonusActivated = await sendManualBonus();

      if (bonusActivated) {
        bonusValue.value = '';
        depositBonusCode.value = undefined;
      } else {
        try {
          depositBonusCode.value = await addBonusCode(bonusValue.value, BONUS_CONSTANTS.MAX_ATTEMPTS);
        } catch (e) {
          const { showAlert } = useLayoutStore();
          showAlert(alertsData.value?.global?.somethingWrong || defaultLocaleAlertsData.value?.global?.somethingWrong);
        }
      }
    }
    bonusChecking.value = false;
  };

  const toggleBonusField = async (): Promise<void> => {
    if (depositBonusCode.value) {
      await toggleBonusCode();
    } else {
      bonusValue.value = '';
    }
  };

  const bonusField = ref();
  const touchMove = ref(false);
  const focusInput = (): void => {
    if (!globalStore.isIOSPlatform) return;

    if (touchMove.value) {
      touchMove.value = false;
      return;
    }

    const inputElement: HTMLInputElement =
      bonusField.value.$el.querySelector('input');
    if (inputElement) inputElement.focus();
  };

  watch(
    () => depositBonusCode.value,
    (newValue) => {
      if (!newValue && bonusValue.value) {
        toggleBonusField();
      }
    }
  );

  onMounted(() => {
    if (depositBonusCode.value) {
      bonusValue.value = depositBonusCode.value?.bonusCode || '';
    }
  });
</script>

<style src="~/assets/styles/components/bonus/deposit-code.scss" lang="scss" />
